<template>
  <b-modal
    :visible="modalVisibility[modalId]"
    hide-footer
    no-close-on-backdrop
    hide-header-close
    centered
    size="lg"
  >
    <template #modal-title> Añadir mantenimiento realizado </template>
    <validation-observer ref="refFormObserver">
      <b-form>
        <b-row>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="nombre"
              rules="required"
            >
              <b-form-group label="Selecciona una localización">
                <v-select
                  id="nombre"
                  v-model="selectedZona"
                  :options="zonasList"
                  label="nombre"
                  autofocus
                  trim
                ></v-select>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group label="Realizado por">
                <v-select
                  id="nombre"
                  v-model="selectedUser"
                  :options="usersList"
                  label="name"
                  autofocus
                  trim
                ></v-select>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group label="Fecha del mantenimiento">
                <b-form-datepicker
                  v-model="selectedDate"
                  placeholder="Selecione uma data"
                ></b-form-datepicker>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <!-- Encomienda -->
          <b-col cols="12" lg="4" md="6" sm="6">
            <validation-provider #default="validationContext" name="Encomienda">
              <b-form-group
                label="Encomienda"
                label-for="fh-encomienda"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  id="fh-encomienda"
                  v-model="encomienda"
                  :options="encomiendas"
                  :reduce="(val) => val.id"
                  label="nombre"
                  placeholder="Seleccione"
                  :clearable="true"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" lg="4" md="6" sm="6" v-if="encomienda">
            <validation-provider #default="validationContext" name="Tareas">
              <b-form-group
                label="Tarea"
                label-for="fh-task"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  id="fh-task"
                  v-model="task"
                  :options="getTasks()"
                  :reduce="(val) => val.id"
                  label="nombre"
                  placeholder="Seleccione"
                  :clearable="true"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-button variant="primary" @click="saveMantenimiento()">
              Guardar
            </b-button>

            <b-button class="m-1" variant="danger" @click="closeModal">
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInvalidFeedback,
  BFormValidFeedback,
  BTooltip,
  BTable,
  BCard,
  BButton,
  BRow,
  BCol,
  BModal,
  BFormGroup,
  BForm,
  BFormInput,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import useZonasList from "@/views/work/zonas/useZonasList";
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import zonaStoreModule from "@/views/work/zonas/zonaStoreModule";
import useUsersList from "../system/user/useUsersList";
import userStoreModule from "../system/user/userStoreModule";
import mantenimientoStoreModule from "./mantenimientoStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import useTareasList from "@/views/work/tareas/useTareasList";
import tareaStoreModule from "@/views/work/tareas/tareaStoreModule";

export default {
  components: {
    BTooltip,
    BTable,
    BFormDatepicker,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BForm,
    BCard,
    BModal,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BFormValidFeedback,
  },
  props: {
    modalId: {
      type: Number,
    },
    zona: {
      type: Object,
    },
    modalState: {
      type: Boolean,
    },
  },
  data() {
    return {
      required,
      visible: true,
      selectedDate: "",
      selectedZona: null,
      selectedUser: null,
      mantenimientosList: [],
      encomienda: null,
      task: null,
    };
  },
  computed: {
    modalVisibility() {
      return this.$parent.modalVisibility;
    },
  },
  methods: {
    getTasks() {
      const parentTask = this.encomienda;
      return this.tasks.filter((e) => e.parent && e.parent.id === parentTask);
    },
    closeModal() {
      this.visible = false;
      this.$emit("closeModal", this.modalId);
    },
    async addMantenimiento() {
      this.mantenimientosList.push({
        zona: this.selectedZona,
        user: this.selectedUser,
        date_mantenimiento: this.selectedDate,
      });
    },
    async saveMantenimiento() {
      this.addMantenimiento();
      for (let i = 0; i < this.mantenimientosList.length; i++) {
        await this.$store.dispatch("app-mantenimiento/saveMantenimiento", {
          zona_id: this.mantenimientosList[i].zona.id,
          user_id: this.mantenimientosList[i].user.id,
          date_mantenimiento: this.mantenimientosList[i].date_mantenimiento,
          encomienda: this.encomienda,
          task: this.task
        });
      }

      this.$emit("refetchData");
      this.closeModal();
    },
    deleteZona(itemId) {
      const index = this.mantenimientosList.findIndex(
        (item) => item.id === itemId
      );

      if (index !== -1) {
        this.mantenimientosList.splice(index, 1);
      }
    },
  },
  setup(props) {
    const zonasList = ref([]);
    const usersList = ref([]);

    const ZONAS_APP_STORE_MODULE_NAME = "app-zona";
    const USER_APP_STORE_MODULE_NAME = "app-user";
    const TASK_APP_STORE_MODULE_NAME = "app-tarea";

    if (!store.hasModule(ZONAS_APP_STORE_MODULE_NAME)) {
      store.registerModule(ZONAS_APP_STORE_MODULE_NAME, zonaStoreModule);
    }
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
    }
    if (!store.hasModule(TASK_APP_STORE_MODULE_NAME)) {
      store.registerModule(TASK_APP_STORE_MODULE_NAME, tareaStoreModule);
    }

    const MANTENIMIENTOS_APP_STORE_MODULE_NAME = "app-mantenimiento";
    if (!store.hasModule(MANTENIMIENTOS_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        MANTENIMIENTOS_APP_STORE_MODULE_NAME,
        mantenimientoStoreModule
      );
    }

    onUnmounted(() => {
      if (store.hasModule(MANTENIMIENTOS_APP_STORE_MODULE_NAME))
        store.unregisterModule(MANTENIMIENTOS_APP_STORE_MODULE_NAME);

      if (store.hasModule(ZONAS_APP_STORE_MODULE_NAME))
        store.unregisterModule(ZONAS_APP_STORE_MODULE_NAME);

      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);

      if (store.hasModule(TASK_APP_STORE_MODULE_NAME))
        store.unregisterModule(TASK_APP_STORE_MODULE_NAME);
    });

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetMantenimientoData
    );
    const { listZonas } = useZonasList();
    const { listUsers } = useUsersList();
    const { listTareas } = useTareasList();
    const encomiendas = ref([]);
    const tasks = ref([]);

    listZonas().then((data) => {
      zonasList.value = data.filter(
        (zona) => zona.parent && zona.parent.id === props.zona.id
      );
    });

    listUsers().then((data) => {
      usersList.value = data.filter((user) => user.fk_role == 2);
    });

    listTareas().then((data) => {
      encomiendas.value = data.filter((task) => !task.parent);
      tasks.value = data.filter((task) => task.parent);
    });

    const resetMantenimientoData = () => {
      zonasList.value = null;
    };

    return {
      listZonas,
      zonasList,
      usersList,
      listUsers,
      encomiendas,
      tasks,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
